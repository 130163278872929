














import { ModalFormProfileUnlockTs } from './ModalFormProfileUnlockTs';
export default class ModalFormProfileUnlock extends ModalFormProfileUnlockTs {}
